<template>
  <div class="container">
    <Header :menuIndex="menuIndex" />
    <router-view @tabIndex="tabIndex" />
  </div>
</template>

<script>
export default {
  components: {
    Header: () => import("@/components/Header"),
  },
  name: "Container",
  data() {
    return {
      menuIndex: "1",
    };
  },
  methods: {
    tabIndex(index) {
      this.menuIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  background-color: #f5f5f5;
}
</style>
